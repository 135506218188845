import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

export const insertNewRelicScript = (appId: string | undefined) => {
  if (!appId) {
    console.error('Missing New Relic Application ID');
    return;
  }
  // Populate using values in copy-paste JavaScript snippet.
  const options = {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: false },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
    info: {
      accountID: '4669929',
      trustKey: '4669929',
      agentID: appId,
      licenseKey: 'NRBR-2d260abab42f308fa70',
      applicationID: appId,
    },
    loader_config: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: 'NRBR-2d260abab42f308fa70',
      applicationID: appId,
      sa: 1,
    },
  };
  // The agent loader code executes immediately on instantiation.
  new BrowserAgent(options);
};
